const locationLabel = (id: string) => {
  switch (id) {
    case '101':
    case '103':
      return 'KYGUNCO Bardstown';
    case '104':
      return 'KYGUNCO Louisville';
    default:
      return undefined;
  }
};

const locationUrl = (id: string) => {
  switch (id) {
    case '101':
    case '103':
      return 'https://www.google.com/maps/dir/?q=place_id:ChIJfwecWCBSaIgRguIjuoPha40';
    case '104':
      return 'https://www.google.com/maps/dir/?q=place_id:ChIJu1yzo5WZaYgRU5lrq2lQNhI';
    default:
      return undefined;
  }
};

export const useBusiness = () => {
  return {
    locationLabel,
    locationUrl,
  };
};
